import consola from 'consola';

/**
 * Validate that a form value is not empty.
 */
export function required(value) {
  if (value === undefined || value === null) {
    return false;
  }

  if (Array.isArray(value)) {
    return !!value.length;
  }

  if (typeof value === 'object') {
    // eslint-disable-next-line no-unreachable-loop
    for (const _ in value) {
      return true;
    }
    return false;
  }

  return !!String(value).trim().length;
}

export function validEmail(value) {
  const re
    = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i;
  return re.test(value);
}

export function validName(value) {
  const words = value?.split(' ') || [];
  const regexExpEmojies
    = /([\u00A9\u00AE\u2000-\u3300]|\uD83C[\uD000-\uDFFF]|\uD83D[\uD000-\uDFFF]|\uD83E[\uD000-\uDFFF])/i;

  return value !== null && value.length >= 1 && words.length > 1 && words[1].length > 0 && !regexExpEmojies.test(value);
}

export function validPassword(value) {
  if (!value) {
    return false;
  }

  const hasCorrectLength = value.length >= 8 && value.length <= 255;
  const hasLowerCaseLetter = value.toUpperCase() !== value;
  const hasUpperCaseLetter = value.toLowerCase() !== value;
  const hasDigit = /\d/.test(value);

  return hasCorrectLength && hasLowerCaseLetter && hasUpperCaseLetter && hasDigit;
}

export function isNull(value) {
  return value === null;
}

export function validateForm(config, values) {
  const errors = {};
  let isValid = true;

  for (const [fieldName, rules] of Object.entries(config)) {
    const fieldValue = values[fieldName];
    const firstInvalidRule = rules.find((rule) => rule(fieldValue, values));

    errors[fieldName] = firstInvalidRule?.(fieldValue, values) || null;

    if (errors[fieldName] !== null) {
      isValid = false;
    }
  }

  return {
    errors,
    isValid,
  };
}

export async function validateJWTs(jwts, $jwt, $jwks) {
  function jwkFetcher(header, callback) {
    $jwks.getSigningKey(header.kid, (err, key) => {
      if (err || !key) {
        consola.log(`Error during fetching JWKS: ${err ? err.message : 'no signing key'}`);
        callback(null);
        return;
      }
      const signingKey = key.publicKey || key.rsaPublicKey;
      callback(null, signingKey);
    });
  }
  return new Promise((resolve, reject) => {
    jwts.forEach((jwt) => {
      $jwt.verify(jwt, jwkFetcher, { algorithms: ['RS256'] }, (err) => {
        if (err) {
          consola.log(`Error during verifying tokens: ${err.message}`);
          reject(new Error(err.message));
        }
        resolve();
      });
    });
  });
}
